/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import myInitObject from "../ultis/variable"

function SEO({ description, lang, meta, title, img }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: "image",
          content: myInitObject.cockpitUrl + img,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js" />
      <script src="https://www.googletagmanager.com/gtag/js?id=GTM-PG43VZ6" />
      <script>
        {`if(true) {
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer && window.dataLayer.push(arguments);}
            gtag('js', new Date());
    
            gtag('config', 'GTM-PG43VZ6', {"send_page_view":false});
          }`}
      </script>
      <script>
        {`gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied'
          });`}
      </script>
      <script defer src="/js/cookieControlConfig.js" type="text/javascript" />
      <link href="/css/cookie-control.css" rel="stylesheet" type="text/css" />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
